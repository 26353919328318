import { Questionaires } from 'assets';
import { T_activity_question } from 'types';

import {
  Button,
  Menu,
  MenuItem,
  Container,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { useRef } from 'react';
import { getActivityCoef, hasAnyZeroes } from 'utils';

import { Typography } from '@material-ui/core';
import {
  defaultMeasurementQuestion,
  T_Dispatch_Calculator,
  T_Questionaire,
} from '..';

type QuestionaireProps = {
  dispatch: T_Dispatch_Calculator;
  Questionaire: T_Questionaire;
};
export const QuestionaireComp: React.FC<QuestionaireProps> = ({
  dispatch,
  Questionaire: QuestionaireState,
}) => {
  const Questionaire = Questionaires[0];

  const onToggle = (index: number, newAnswer: defaultMeasurementQuestion) => {
    const newQuestionsState = QuestionaireState.questionsState.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          const { isAnswered, answer, points } = newAnswer;
          return {
            isAnswered,
            isOpen: !item.isOpen,
            answer,
            points,
          };
        }
        return item;
      }
    );

    const newPoints = newQuestionsState.reduce(
      (accumulator, item) => accumulator + item.points ?? 0,
      0
    );
    const newResult = getActivityCoef(Questionaire.questionaireID, newPoints);
    const areAllAnswered = !hasAnyZeroes(
      newQuestionsState.map((item) => item.points)
    );

    dispatch({
      type: 'update questionaire',
      value: {
        ...QuestionaireState,
        areAllAnswered,
        result: newResult,
        points: newPoints,
        questionsState: newQuestionsState,
      },
    });
  };

  return (
    <Container
      maxWidth="xl"

      //   style={{
      //     display: 'flex',
      //     height: window.innerHeight - window.innerHeight / 7,
      //     flexDirection: 'column',
      //     marginLeft: 10,
      //   }}
    >
      <div style={{ flex: 1 }}>
        <Typography variant="h4">Въпросник за активност* </Typography>
        {Questionaire.QuestionsAndAnswers.map((item, qIndex) => {
          return (
            <RenderQuestion
              item={item}
              key={qIndex}
              state={QuestionaireState.questionsState[qIndex]}
              onToggle={(newAnswer: defaultMeasurementQuestion) =>
                onToggle(qIndex, newAnswer)
              }
            />
          );
        })}
        <p>Точки: {QuestionaireState.points ?? ''}</p>
        Коефициент на активност:{' '}
        {QuestionaireState.areAllAnswered
          ? QuestionaireState.result
          : 'Отговори на всички въпроси първо'}
      </div>
      <div
        style={{
          //   height: window.innerHeight,
          display: 'flex',
          flex: 10,
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <h6>*Приспособен от https://www.fatlosstroubleshoot.com/ </h6>
      </div>
    </Container>
  );
};

type RenderQuestionProps = {
  state: defaultMeasurementQuestion;
  item: T_activity_question;
  onToggle: (newAnswer: defaultMeasurementQuestion) => void;
};
const RenderQuestion: React.FC<RenderQuestionProps> = ({
  item,
  state,
  onToggle,
}) => {
  const Q_Item: T_activity_question = item;
  const anchorEl = useRef<null | HTMLElement>(null);
  const isMobile = useMediaQuery('(min-width:600px)');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl.current = event.currentTarget;
    onToggle({ answer: null, points: 0, isAnswered: false, isOpen: true });
  };

  const handleClose = () => {
    anchorEl.current = null;
  };

  const onChoose = (answerIndex) => {
    onToggle({
      answer: Q_Item.answers[answerIndex].answerString,
      isAnswered: true,
      isOpen: false,
      points: Q_Item.answers[answerIndex].points,
    });
  };

  return (
    <Grid
      style={{
        fontSize: 15,
        display: 'flex',
        alignItems: 'center',
        flexDirection: isMobile ? 'row' : 'column',
      }}
    >
      <Button onClick={handleClick} style={{ fontSize: 15 }}>
        <Typography>{Q_Item.question}</Typography>
      </Button>{' '}
      {state.answer ? (
        <Typography variant="subtitle1" color="textPrimary">
          {state.answer}
        </Typography>
      ) : (
        <Typography variant="subtitle1" color="error">{`отговор`}</Typography>
      )}
      <div style={{ height: 15 }}></div>
      <Menu
        open={state.isOpen}
        anchorEl={anchorEl.current}
        onClose={handleClose}
      >
        {Q_Item.question}
        {Q_Item.answers.map((answer, index) => {
          return (
            <MenuItem onClick={() => onChoose(index)} key={index}>
              {answer.answerString}
            </MenuItem>
          );
        })}
      </Menu>
    </Grid>
  );
};
