import { useReducer } from 'react';
import { T_Dispatch_Calculator, T_Reducer_Calculator } from '..';
import { getMaintenanceFromState } from '../utils';

import { initialCalculatorState, T_State_Calculator } from './initialState';

const calculatorReducer: T_Reducer_Calculator = (
  state,
  action
): T_State_Calculator => {
  switch (action.type) {
    case 'update questionaire': {
      return {
        ...state,
        Questionaire: action.value,
      };
    }

    case 'update tape measure component': {
      return {
        ...state,
        TapeMeasureComponent: action.value,
      };
    }

    case 'update basic measures': {
      return {
        ...state,
        Basics: action.value,
      };
    }

    case 'update goals': {
      return { ...state, Goals: { ...action.value } };
    }

    default: {
      console.error('Unexpected action in Calculator reducer');
      return state;
    }
  }
};

type useCalculatorReducerProps = {
  state: T_State_Calculator;
  dispatch: T_Dispatch_Calculator;
};

export const useCalculatorReducer = (): useCalculatorReducerProps => {
  const [state, dispatch] = useReducer<T_Reducer_Calculator>(
    calculatorReducer,
    initialCalculatorState
  );

  return { state, dispatch } as const;
};
