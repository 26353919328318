import { T_State_Calculator } from './state/initialState';
import { errors } from './errors';
import { calFormulas, getFatWeight_TapeMeasure } from 'utils';

export const getBasics = (state: T_State_Calculator) => {
  const { height, weight, sex, age } = state.Basics;
  const hips = state.TapeMeasureComponent.measures.find(
    (item) => item.Name === 'hips'
  ).Value;
  const neck = state.TapeMeasureComponent.measures.find(
    (item) => item.Name === 'neck'
  ).Value;
  const waist = state.TapeMeasureComponent.measures.find(
    (item) => item.Name === 'waist'
  ).Value;
  return { hips, neck, waist, height, weight, sex, age };
};

type T_FatWeight = ReturnType<typeof getFatWeight_TapeMeasure>;
export type T_FatResult = T_FatWeight & {
  error: ReturnType<typeof errors.basic>;
};
export const getFatResultsByState = (
  state: T_State_Calculator
): T_FatResult => {
  const { height, weight, sex, age, hips, neck, waist } = getBasics(state);

  const fatErrors = errors.basic({ weight, height, age });

  return {
    ...getFatWeight_TapeMeasure({
      height,
      weight,
      sex,
      hips,
      neck,
      waist,
    }),
    error: fatErrors,
  };
};

export const getMaintenanceFromState = (
  state: T_State_Calculator
): { maintenance: string; error: boolean } => {
  const { sex, weight, age, height, hips, neck, waist } = getBasics(state);

  const maintenance = calFormulas.getMaintenance(
    sex,
    parseFloat(weight),
    age,
    height,
    'cut',
    state.Questionaire.result
  );

  const maintenanceError =
    isNaN(maintenance) || !state.Questionaire.areAllAnswered;
  // !state.Questionaire.areAllAnswered ||
  // fatResults.error;
  return { maintenance: maintenance.toString(), error: maintenanceError };
};
