import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import * as C from './colors';

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: C.lightTheme.color1,
      },
      secondary: {
        main: C.lightTheme.color2,
      },
    },
  })
);
