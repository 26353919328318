import { T_Dispatch_Calculator, T_State_TapeMeasurementComponent } from '..';
import { measurementTranslations } from 'utils';

type TapeMeasureComponentProps = {
  state: T_State_TapeMeasurementComponent;
  dispatch: T_Dispatch_Calculator;
};
export const TapeMeasureComponent: React.FC<TapeMeasureComponentProps> = ({
  state,
  dispatch,
}) => {
  const { measures } = state;
  const t = measurementTranslations;

  const onUpdateMeasure = (
    e: React.ChangeEvent<HTMLInputElement>,
    measureIndex: number
  ) => {
    const newTapeMeasures = state.measures.map((item, index) => {
      if (index === measureIndex) return { ...item, Value: e.target.value };
      return item;
    });
    dispatch({
      type: 'update tape measure component',
      value: { ...state, measures: newTapeMeasures },
    });
  };
  return (
    <div>
      {measures.map(({ Name, Value }, measureIndex) => {
        return (
          <div key={Name}>
            {t(Name)}
            <input
              value={Value}
              onChange={(e) => onUpdateMeasure(e, measureIndex)}
            />
          </div>
        );
      })}
    </div>
  );
};
