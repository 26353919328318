import { Questionaires } from 'assets';
import { T_Measurement_Anonymous, T_TapeMeasure } from 'types';
import {
  defaultMeasurementQuestion,
  T_Questionaire,
  T_State_TapeMeasurementComponent,
} from '..';
import { T_FatResult } from '../utils';

const Questionaire = Questionaires[0];

export const defaultQuestionaireQuestions: defaultMeasurementQuestion[] =
  Questionaire.QuestionsAndAnswers.map((item) => {
    return {
      isOpen: false,
      answer: null,
      isAnswered: false,
      points: 0,
    } as defaultMeasurementQuestion;
  });

const initialMeasures: T_TapeMeasure[] = [
  {
    Name: 'neck',
    Value: '',
  },
  { Name: 'hips', Value: '' },
  { Name: 'waist', Value: '' },
];

export type T_State_Goals = {
  basicMetabolicRate: string;
  fatResults: T_FatResult;
  maintenance: string;
  maintenanceError: string;
  wantTo: 'cut' | 'bulk' | 'maintain';
  recommendedDeficitOrSurplusPercent: number;
  deficitOrSurplusPercent: string;
  goalCalories: string;
};
export type T_State_Calculator = {
  Basics: T_Measurement_Anonymous;
  Questionaire: T_Questionaire;
  TapeMeasureComponent: T_State_TapeMeasurementComponent;
  Goals: T_State_Goals;
};
export const initialCalculatorState: T_State_Calculator = {
  Questionaire: {
    isOpen: true,
    questionsState: defaultQuestionaireQuestions,
    areAllAnswered: false,
    points: 0,
    result: 0,
  },
  TapeMeasureComponent: {
    isOpen: true,
    measures: initialMeasures,
  },
  Basics: {
    age: null,
    height: null,
    sex: 'male',
    weight: '',
  },
  Goals: {
    basicMetabolicRate: '',
    fatResults: { error: '', fatPercent: 0, fatWeight: 0, leanWeight: 0 },
    recommendedDeficitOrSurplusPercent: 0,
    deficitOrSurplusPercent: '10',
    wantTo: 'cut',
    maintenance: '',
    maintenanceError: '',
    goalCalories: '',
  },
};
