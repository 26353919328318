const originalTheme = {
  background: 'white',
  color0: '#000000',
  color1: '#0085C8',
  color2: '#00B456',
  color3: '#805E73',
  colorYellow: '#FFC000',
  blue1: '#38BBFF',
  blue2: '#00A1F2',
  blue3: '#0085C8',
  blue_transparent: '#0085C835',
  green1: '#00E76F',
  green2: '#00B456',
  green3: '#009246',
  green_transparent: '#00924635',
  purple1: '#805E73',
  purple2: '#634959',
  purple3: '#523C4A',
  grey1: '#595959',
  grey2: '#404040',
  black: '#000000',
  white: 'white',
  red: 'red',
  transparent: function (color: string, transperancy = 25) {
    return `${this[color]}${transperancy}`;
  },
} as const;

export const lightTheme = {
  ...originalTheme,
  color1: originalTheme.blue3,
  color2: originalTheme.green3,
  color3: originalTheme.purple3,
} as const;

export const darkTheme = {
  ...lightTheme,
  background: '#000000',
  color0: '#A6A6A6',
  black: '#A6A6A6',
  color1: '#65CCFF',
  color2: '#19FF87',
  color3: '#A17F94',
  colorYellow: '#FFD54F',
} as const;
