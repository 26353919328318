import { Button } from '@material-ui/core';
import { T_Measurement_Anonymous } from 'types';
import { T_Dispatch_Calculator } from '..';

import { measurementTranslations } from 'utils';

type BasicMeasurementProps = {
  state: T_Measurement_Anonymous;
  dispatch: T_Dispatch_Calculator;
};
export const BasicMeasurement: React.FC<BasicMeasurementProps> = ({
  state,
  dispatch,
}) => {
  const t = measurementTranslations;

  const { height, weight, dateStamp, age, sex } = state;
  const stateArr: { Name: string; Value: string }[] = [];
  for (const key in state)
    if (key !== 'sex' && key !== 'dateStamp')
      stateArr.push({ Name: key, Value: state[key] });

  const onChangeValue = (Name: string, newValue: string) => {
    const newState = { ...state };
    newState[Name] = newValue;
    dispatch({ type: 'update basic measures', value: newState });
  };

  const isMale = state.sex === 'male';

  return (
    <div>
      <Button
        color={isMale ? 'primary' : 'default'}
        onClick={() => onChangeValue('sex', 'male')}
      >
        Мъж
      </Button>
      <Button
        color={!isMale ? 'primary' : 'default'}
        onClick={() => onChangeValue('sex', 'female')}
      >
        Жена
      </Button>

      {stateArr.map(({ Name, Value }) => {
        return (
          <div key={Name}>
            {t(Name)}
            <input
              value={Value ?? ''}
              onChange={(e) => onChangeValue(Name, e.target.value)}
            />
          </div>
        );
      })}
    </div>
  );
};
