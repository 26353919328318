import { useEffect } from 'react';
import { calFormulas, deficit_surplus } from 'utils';
import { T_Dispatch_Calculator } from '.';
import { T_State_Calculator } from './state/initialState';
import { getFatResultsByState, getMaintenanceFromState } from './utils';

export const useUpdateGoals = (
  state: T_State_Calculator,
  dispatch: T_Dispatch_Calculator
) => {
  useEffect(() => {
    const isDeficit: deficit_surplus =
      state.Goals.wantTo === 'cut' ? 'deficit' : 'surplus';
    const { error, maintenance } = getMaintenanceFromState(state);
    const fatResults = getFatResultsByState(state);
    const recommendedChangePercent = isDeficit
      ? calFormulas.getMaxDeficit(state.Basics.sex, fatResults.fatPercent)
      : calFormulas.getMaxBulk('novice');

    if (error) {
      dispatch({
        type: 'update goals',
        value: { ...state.Goals, maintenanceError: '', maintenance: '' },
      });
    }
    if (!error) {
      const { deficitOrSurplusPercent } = state.Goals;
      const newCalorieGoal = calFormulas.getCalories(
        isDeficit,
        parseFloat(maintenance),
        parseFloat(deficitOrSurplusPercent)
      );
      dispatch({
        type: 'update goals',
        value: {
          ...state.Goals,
          fatResults,
          maintenance,
          goalCalories: `${newCalorieGoal}`,
          recommendedDeficitOrSurplusPercent: recommendedChangePercent,
        },
      });
    }
  }, [
    state.Basics,
    state.TapeMeasureComponent,
    state.Goals.wantTo,
    state.Goals.deficitOrSurplusPercent,
  ]);
};
