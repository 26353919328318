import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import Home from './pages/Home';
import CalculatorPage from './pages/CalculatorPage/Calculator';
import { GlobalHeader } from './components/Molecules/MainHeader/MainHeader';

import { theme } from 'theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* <GlobalHeader />
        <Route exact path="/home">
          <Home />
        </Route> */}
        <Switch>
          {/* <Route exact path="/#calculator">
            <CalculatorPage />
          </Route>
          <Route exact path="/">
            <Redirect to="/#calculator" />
          </Route> */}
          <Route component={CalculatorPage} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
