import { Box, Typography } from '@material-ui/core';
import { Questionaires } from 'assets';
import { useEffect } from 'react';
import styled from 'styled-components';
import { T_Measurement_Anonymous, T_TapeMeasure } from 'types';
import { calFormulas, getActivityCoef } from 'utils';
import { useUpdateGoals } from './Calculator.hooks';

import {
  TapeMeasureComponent,
  BasicMeasurement,
  QuestionaireComp,
  Goals,
  T_onUpdate_Goals,
} from './components';

import { errors } from './errors';

import { useCalculatorReducer } from './state/Calculator.reducer';
import * as utils from './utils';

const CalculatorPage = () => {
  const { state, dispatch } = useCalculatorReducer();

  const onAddDummyData = () => {
    const newBasics: T_Measurement_Anonymous = {
      age: 35,
      height: 175,
      sex: 'female',
      weight: '71',
    };
    const newQuestionaireAnswers = state.Questionaire.questionsState.map(
      (quest) => {
        return {
          ...quest,
          isAnswered: true,
          isOpen: false,
          points: 2,
          answer: 'автоматичен',
        };
      }
    );
    const newTapeMeasures: T_TapeMeasure[] = [
      { Name: 'hips', Value: '110' },
      { Name: 'neck', Value: '40' },
      { Name: 'waist', Value: '80' },
    ];

    const newPoints = newQuestionaireAnswers.reduce(
      (accumulator, item) => accumulator + item.points ?? 0,
      0
    );
    const newResult = getActivityCoef(
      Questionaires[0].questionaireID,
      newPoints
    );

    dispatch({ type: 'update basic measures', value: newBasics });
    dispatch({
      type: 'update questionaire',
      value: {
        areAllAnswered: true,
        questionsState: newQuestionaireAnswers,
        isOpen: true,
        points: newPoints,
        result: newResult,
      },
    });
    dispatch({
      type: 'update tape measure component',
      value: { isOpen: true, measures: newTapeMeasures },
    });
  };

  const onUpdateGoals: T_onUpdate_Goals = ({ value }) => {
    dispatch({ type: 'update goals', value });
  };

  useUpdateGoals(state, dispatch);

  const { fatResults } = state.Goals;

  return (
    <>
      <button onClick={onAddDummyData}>попълни автоматично</button>
      <Boxi>
        <BasicMeasurement state={state.Basics} dispatch={dispatch} />
        {fatResults.error && (
          <Typography color="error">{fatResults.error}</Typography>
        )}
        {state.TapeMeasureComponent.isOpen && (
          <TapeMeasureComponent
            state={state.TapeMeasureComponent}
            dispatch={dispatch}
          />
        )}
      </Boxi>

      {state.Questionaire.isOpen && (
        <QuestionaireComp
          Questionaire={state.Questionaire}
          dispatch={dispatch}
        />
      )}
      <Goals state={state} onUpdate={onUpdateGoals} />
    </>
  );
};
export default CalculatorPage;

const Boxi = styled.div`
  display: flex;
  flex-direction: row;
`;
