import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { calFormulas, round } from 'utils';
import { errors } from '../errors';
import { T_State_Calculator, T_State_Goals } from '../state/initialState';
import * as utils from '../utils';

type T_WantTo = 'cut' | 'bulk' | 'maintain';
export type T_onUpdate_Goals_Value = {
  value: T_State_Goals;
};
export type T_onUpdate_Goals = (action: T_onUpdate_Goals_Value) => void;
export const Goals: React.FC<{
  state: T_State_Calculator;
  onUpdate: T_onUpdate_Goals;
}> = ({ state, onUpdate }) => {
  const maintenanceError = state.Goals.maintenanceError;
  const maintenance = state.Goals.maintenance;

  const onChangePercent = (newValue: string) => {
    onUpdate({ value: { ...state.Goals, deficitOrSurplusPercent: newValue } });
  };

  const onChangeCutBulk = (newValue: T_WantTo) => {
    onUpdate({ value: { ...state.Goals, wantTo: newValue } });
  };

  const isCutting = state.Goals.wantTo === 'cut';
  const ButtonToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          color={isCutting ? 'primary' : 'default'}
          onClick={() => onChangeCutBulk('cut')}
        >
          Кът
        </Button>
        <Button
          color={!isCutting ? 'primary' : 'default'}
          onClick={() => onChangeCutBulk('bulk')}
        >
          Бълк
        </Button>
      </div>
    );
  };

  const tapeMeasureErrors = errors.tapeMeasures(
    state.TapeMeasureComponent.measures,
    state.Basics
  );
  const { fatResults } = state.Goals;

  return (
    <div>
      {maintenanceError && (
        <Typography color="error">
          Грешка. Виж дали са попълнени всички полета
        </Typography>
      )}
      <div>
        <Typography color="error">{tapeMeasureErrors ?? ''}</Typography>
        {!fatResults.error && (
          <>
            <Typography>Процент мазнини: {fatResults.fatPercent}</Typography>
            <Typography>Мазнини (кг){fatResults.fatWeight}</Typography>
            <Typography>Чисто тегло (кг){fatResults.leanWeight}</Typography>
          </>
        )}
      </div>
      {!maintenanceError && (
        <div>
          Поддържаш на: {round(maintenance, 0)}
          <ButtonToggle />
          <p></p>
          <FlexDiv>
            <Typography>
              % Процент {isCutting ? 'дефицит' : 'излишък'}
            </Typography>
            <input
              value={state.Goals.deficitOrSurplusPercent}
              onChange={(e) => onChangePercent(e.target.value)}
            />
            <Typography>
              Препоръчителен процент{' '}
              {state.Goals.recommendedDeficitOrSurplusPercent}%
            </Typography>
          </FlexDiv>
          <Typography>Цел: {round(state.Goals.goalCalories, 0)}ккал</Typography>
        </div>
      )}
    </div>
  );
};

const FlexDiv = styled.div`
  display: flex;
`;
