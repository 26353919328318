export const measurementTranslations = (translateWord: string): string => {
  switch (translateWord) {
    case 'age':
      return 'Възраст';
    case 'height':
      return 'Ръст';
    case 'weight':
      return 'Тегло';
    case 'neck':
      return 'Врат';
    case 'hips':
      return 'Ханш';
    case 'waist':
      return 'Талия';
  }
};
